
import { mapState } from 'vuex'
import config from '@/modules/config/header'

export default {
  components: {},
  props: {
    propsData: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      // isLoaded: false,
      logoBlack: config[this.$route.params.site]?.logo[this.$route.params.lang],
      moduleName: '',
      searchInput: '',
      announcementSidebar: {},
      isPopups: false,
    }
  },
  computed: {
    ...mapState({
      isHome: (state) => state.isHome,
      isHdFtMobile: (state) => state.isHdFtMobile,
      isPopup: (state) => state.isPopup,
      router: (state) => state.router,
      isAgree: (state) => state.cookie_agree.agree,
    }),
  },
  watch: {
    isPopup(val) {
      this.isPopups = val
      if (val) {
        // 禁止蒙层下面一起滚动
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    },
  },
  async mounted() {
    try {
      // this.isLoaded = true
      // 获取重要公告
      const res = await this.$API.common.getAnnouncementSidebarApi()
      const pageInfo = res || {}
      this.announcementSidebar = pageInfo.announcementSidebar || {}
    } catch (e) {
      this.announcementSidebar = {}
    }
  },
  methods: {
    setDefaultActive() {
      // 选中
      const fullPath = this.$route.fullPath
      const path = fullPath.split('?')[0]
      const moduleName = path.split('/')[3]
      this.moduleName = moduleName || 'home'
    },
    // 移动端点击菜单svg事件
    togglePopup() {
      this.isPopups = !this.isPopups
      this.$store.commit('saveIsPopup', this.isPopups)
    },
    // 移动端点击菜单外关闭左侧菜单
    closePopups() {
      this.$store.commit('saveIsPopup', false)
    },
    backToHomePage() {
      const curLang = this.$route.params.lang
      const curSite = this.$route.params.site
      const url = window.location.origin + '/' + curSite + '/' + curLang
      location.replace(url)
    },
  },
}
